<template>
  <div>
    <b-row no-gutters>
      <b-col class="text-left answe-details">
        <span class="iconfont icon-dati"></span> 答题详情</b-col
      >
    </b-row>
    <b-row>
      <b-col class="item-title"
        >选择题部分 共{{
          $route.query.sprintId == 1
            ? 75
            : $route.query.sprintId == 4
            ? 60
            : 105
        }}分</b-col
      >
    </b-row>
    <b-modal
      size="lg"
      id="modal-mock-listening"
      centered
      modal-class="mock-listeningText-modal"
      dialog-class="mock-listeningText-dialog"
      body-class="mock-listeningText-body"
      button-size="lg"
      hide-footer
    >
      <div class="listening-text" v-html="textHtml"></div>
    </b-modal>
    <div
      class="test-item"
      :class="[ModuleTitleClass(section.sectionType)]"
      v-for="(section, index) in examSectionInfos"
      :key="index"
    >
      <b-row align-v="center">
        <div class="module-title">
          <div class="section-title text-left">
            <span>{{ roman(index) }}.</span>
            {{ section.statement }} （{{ section.sectionTotalScores }}）
            <!-- <span
                  @click="openListeningModal"
                  v-if="$route.query.module = 3 && index == 0"
                  class="click-listening-text"
                  >查看听力原文</span
                > -->
          </div>
          <!-- 非听力 @click="getQuestions(index, section.sectionId)"-->
          <div
            class="down-button"
            v-if="section.sectionType !== 1"
            v-b-toggle="'mocktest-coll' + index"
          >
            <div v-if="unfold[index]">
              <span>展开试题</span>
              <span class="iconfont icon-jiantou11"></span>
            </div>
            <div v-if="!unfold[index]">
              <span>隐藏试题</span>
              <span class="iconfont icon-jiantou1"></span>
            </div>
          </div>
          <!-- 听力 @click="completeListening(index, section.sectionId)"-->
          <div
            class="down-button"
            v-b-toggle="'mocktestCollListening'"
            v-if="section.sectionType === 1"
          >
            <div v-if="unfold[index]">
              <span>展开试题</span>
              <span class="iconfont icon-jiantou11"></span>
            </div>
            <div v-if="!unfold[index]">
              <span>隐藏试题</span>
              <span class="iconfont icon-jiantou1"></span>
            </div>
          </div>
        </div>

        <div cols="12">
          <div class="text-left fullScoreEvaluation">
            <span class="iconfont icon-biaoji green"></span>
            <span>满分：{{ section.sectionTotalScores }}</span>
            <span class="iconfont icon-biaoji1 yellow"></span>
            <span>得分：{{ section.sectionAnswerScores }}</span>
          </div>
          <div class="text-left" v-if="section.answerEvaluation">
            <span>
              <span class="iconfont icon-pingjia-tianchong green"></span>评价：
              {{ section.answerEvaluation }}
            </span>
          </div>
        </div>
        <div cols="12" class="unfold-mock-listening">
          <b-container
            class="written-expression-title"
            id="mocktestCollListening"
            v-if="section.sectionType === 1"
          >
            <b-row
              class="group-content"
              v-for="(item, index) in testData.sectionAnswerExamQuestions"
              style="text-align: left"
              :key="item.sectionId"
            >
              <b-col style="padding: 0">
                <b-row>
                  <b-col class="group-contentTitle">
                    <div class="group-stament">
                      {{ item.statement ? item.statement : "" }}
                    </div>
                  </b-col>
                </b-row>
                <b-row class="question-description">
                  <b-col
                    :class="
                      index == 2
                        ? 'BigTitlePlayButton'
                        : index == 3
                        ? 'BigTitlePlayButton'
                        : 'fullScoreEvaluation'
                    "
                    cols="12"
                  >
                    <span
                      @click="
                        startVoice(
                          item.classificationId,
                          item.examQuestionExtends[0].voiceUrl
                        )
                      "
                      v-show="index >= 2 && !voiceStates[item.classificationId]"
                      class="iconfont iconbofang1"
                    ></span>
                    <span
                      @click="stopVoice"
                      v-show="index >= 2 && voiceStates[item.classificationId]"
                      class="iconfont iconzanting"
                    ></span>
                    <span class="iconfont iconbiaoji green"></span>
                    <span>满分：{{ item.sectionTotalScores }}分</span>
                    <span class="iconfont iconbiaoji1 yellow"></span>
                    <span>得分：{{ item.sectionAnswerScores }}分</span>
                  </b-col>
                  <b-col class="fullScoreEvaluation">
                    <span class="iconfont iconpingjia-tianchong green"></span>
                    <span>评价：{{ item.answerEvaluation }}</span>
                  </b-col>
                </b-row>
                <b-row
                  v-for="question in item.examQuestionExtends"
                  :key="question.questionId"
                  class="row-content"
                >
                  <b-col :class="index == 0 ? 'testQuestions' : ''">
                    <b-row class="question-stament">
                      <b-col>
                        <div
                          :class="
                            index == 2
                              ? 'answerTitle'
                              : index == 3
                              ? 'answerTitle'
                              : 'question-title'
                          "
                        >
                          <span
                            @click="
                              startVoice(question.variation, question.voiceUrl)
                            "
                            v-show="
                              question.voiceUrl &&
                              index < 2 &&
                              !voiceStates[question.variation]
                            "
                            class="iconfont iconbofang1"
                          ></span>
                          <span
                            @click="stopVoice"
                            v-show="
                              voiceStates[question.variation] && index < 2
                            "
                            class="iconfont iconzanting"
                          ></span>
                          <span>
                            {{
                              question.variation +
                              ". " +
                              (question.statement ? question.statement : "")
                            }}
                          </span>
                        </div>
                      </b-col>
                    </b-row>
                    <b-col class="QuestionContent">
                      <b-form-radio-group
                        v-model="question.userAnswerKey"
                        style="display: flex; flex-wrap: wrap"
                        size="lg"
                      >
                        <b-col
                          md="4"
                          cols="12"
                          v-for="option in question.options"
                          class="question-option"
                          :key="option.value"
                        >
                          <b-form-radio
                            :class="getClassName(question, option)"
                            :value="option.value"
                            disabled
                          ></b-form-radio>
                          <span :class="getClassName(question, option)">{{
                            option.value + ". " + option.statement
                          }}</span>
                          <span
                            v-if="question.standardAnswerKey === option.value"
                            class="iconfont icon-duihao"
                          ></span>
                        </b-col>
                      </b-form-radio-group>
                    </b-col>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
          <b-container
            class="written-expression-title"
            :id="'mocktest-coll' + index"
          >
            <div
              v-for="examQuestion in sections.sectionAnswerRecords[index]
                .sectionAnswerRecordsInfos"
              :key="examQuestion.questionId"
            >
              <div v-if="![6, 7, 8].includes(section.sectionType)">
                <div v-if="examQuestion.description" class="testinfor-title">
                  {{ examQuestion.description }}
                </div>
                <div
                  v-if="examQuestion.stemStatement"
                  v-html="examQuestion.stemStatement"
                  class="stem-statement"
                >
                  {{ examQuestion.stemStatement }}
                </div>
                <select-question
                  showTheAnswer
                  v-for="question in examQuestion.sectionAnswerRecords"
                  :examQuestion="question"
                  :setionId="section.sectionId"
                  :sectionType="section.sectionType"
                  @changeRadio="changeRadio"
                  :key="question.questionId"
                />
              </div>
              <div v-if="[6, 7].includes(section.sectionType)">
                <div
                  v-if="examQuestion.description"
                  class="testinfor-title"
                  v-html="examQuestion.description"
                ></div>
                <div style="display: flex">
                  <div
                    v-if="examQuestion.stemStatement"
                    class="stem-statement"
                    v-html="
                      filterHtml(
                        examQuestion.stemStatement,
                        examQuestion.sectionAnswerRecords
                      )
                    "
                  ></div>
                  <div
                    v-for="(question, i) in examQuestion.sectionAnswerRecords"
                    :key="i"
                  >
                    <div
                      :class="question.questionId"
                      ref="questionHtml"
                      @input="getInput(index, question.questionId)"
                      v-html="question.statement"
                    ></div>
                    <ParsingModal
                      v-show="parseShow(section.sectionType, i)"
                      :dataParsing="question.standardAnswer"
                      :variation="question.variation"
                    ></ParsingModal>
                  </div>
                </div>
              </div>
              <div v-if="section.sectionType === 8">
                <div
                  class="examQuestion-description"
                  v-if="examQuestion.description"
                >
                  <div class="testinfor-title">
                    {{ examQuestion.description }} （{{
                      examQuestion.totalScore
                    }}）
                  </div>
                  <span class="iconfont icon-biaoji green"></span>
                  <span>满分：{{ examQuestion.totalScore }}</span>
                  <span class="iconfont icon-biaoji1 yellow"></span>
                  <span>得分：{{ examQuestion.answerScore }}</span>
                  <div class="fullScoreEvaluation">
                    <span class="iconfont iconpingjia-tianchong">
                      <span class="mock-evaluation"
                        >评价：{{ examQuestion.answerEvaluation }}</span
                      >
                    </span>
                  </div>
                </div>
                <!-- v-html="
                    filterTextareaHtml(
                      examQuestion.stemStatement,
                      examQuestion.sectionAnswerRecords
                    )
                  " -->
                <div
                  v-if="examQuestion.stemStatement"
                  v-html="examQuestion.stemStatement"
                  class="stem-statement"
                ></div>
                <div
                  v-for="question in examQuestion.sectionAnswerRecords"
                  :key="question.questionId"
                  class="situational-application"
                >
                  <b-row
                    :data-qq="examQuestion.plyTkSectionId"
                    cols="1"
                    v-if="examQuestion.plyTkSectionId == 19"
                  >
                    <b-row>
                      <b-col class="written-expression-topic">
                        <span class="topic-right">{{
                          question.variation + ". "
                        }}</span>
                        <span
                          class="question-statement"
                          v-html="question.statement"
                        ></span>
                      </b-col>
                      <b-col sm="1" class="parse-class" align-self="start">
                        <ParsingModal
                          v-show="
                            $route.meta.startReportImg ||
                            $route.meta.smallReportImg ||
                            $route.meta.bigReportImg ||
                            $route.meta.mockReportImg
                          "
                          :dataParsing="question.standardAnswer"
                          :variation="question.variation"
                        ></ParsingModal>
                      </b-col>
                    </b-row>

                    <b-col class="own-answer" cols="10">
                      <div>你的答案</div>
                      <div>
                        {{ question.userAnswerKey }}
                      </div>
                    </b-col>
                    <b-col class="Self-assessment-score">
                      <span>自评得分：</span>
                      <span>{{ question.selfScore }}</span>
                    </b-col>
                    <b-col class="standard-answer" cols="10">
                      <div>标准答案</div>
                      <div v-html="question.standardAnswerKey">
                        {{ question.standardAnswerKey }}
                      </div>
                    </b-col>
                  </b-row>
                  <div v-else>
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <span class="topic-right">{{
                          question.variation + ".  "
                        }}</span>
                        <span v-html="question.statement"></span>
                      </div>
                      <ParsingModal
                        v-show="
                          $route.meta.startReportImg ||
                          $route.meta.smallReportImg ||
                          $route.meta.bigReportImg ||
                          $route.meta.mockReportImg
                        "
                        :dataParsing="question.standardAnswer"
                        :variation="question.variation"
                      ></ParsingModal>
                    </div>

                    <div class="own-answer" cols="10">
                      <div></div>
                      <div class="own-answer-img">
                        <el-image
                          v-if="question.userAnswerKey"
                          style="width: 200px; height: 100%"
                          :src="
                            imgURl +
                            question.userAnswerKey.replace('image:', '')
                          "
                          :preview-src-list="[
                            imgURl +
                              question.userAnswerKey.replace('image:', ''),
                          ]"
                        >
                        </el-image>
                      </div>
                      <div></div>
                    </div>
                    <div class="Self-assessment-score">
                      <span>自评得分：</span>
                      <span>{{ question.selfScore }}</span>
                    </div>
                    <div class="standard-answer" cols="10">
                      <div>范文</div>
                      <div v-html="question.standardAnswerKey">
                        {{ question.standardAnswerKey }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-container>
        </div>
      </b-row>
      <b-row v-if="section.sectionType === 5">
        <b-col class="item-title"
          >非选择题部分 共{{ $route.query.sprintId == 4 ? 40 : 45 }}分</b-col
        >
      </b-row>
    </div>
  </div>
</template>

<script>
import { Image } from "element-ui";
import SelectQuestion from "@/views/english-mock/components/SelectQuestion.vue";
// import { pdfEvaluationReport } from "@/api/english-examination";
import ParsingModal from "@/components/global-popup/global-parsing-modal.vue";
import { mapMutations } from "vuex";
const imgURl = process.env.VUE_APP_API_BASE_URL + "/api/media/getanswerimage/";
export default {
  data() {
    return {
      textHtml: "",
      imgURl,
    };
  },
  components: {
    SelectQuestion,
    "el-image": Image,
    ParsingModal,
  },
  props: {
    sections: {
      default: function () {
        return {};
      },
    },
    unfold: {
      default: function () {
        return {};
      },
    },
    testData: {
      default: function () {
        return {};
      },
    },
    voiceStates: {
      default: function () {
        return {};
      },
    },
    examSectionInfos: {
      default: function () {
        return [];
      },
    },
  },
  mounted() {
    //   this.getQuestions()
  },
  methods: {
    ...mapMutations(["setSimulationExamQuestions"]),
    parseShow(sectionType, index) {
      if (
        this.$route.meta.startReportImg ||
        this.$route.meta.smallReportImg ||
        this.$route.meta.bigReportImg ||
        this.$route.meta.mockReportImg
      ) {
        if (index == 0) {
          return true;
        }
      } else {
        return false;
      }
    },
    filterHtml(html, arr) {
      const dom = document.createElement("div");
      dom.innerHTML = html;
      const inputList = dom.getElementsByTagName("input");
      Array.prototype.forEach.call(inputList, (item, i) => {
        const span = document.createElement("span");
        let color = "#CC0000";
        let standardAnswerKeySpan = `
          <span style="font-weight: bold;color: #000000;">（<span style="font-weight: normal;color: #2CB774">${arr[i].standardAnswerKey}</span>）</span>
        `;
        if (arr[i].userAnswerKey === arr[i].standardAnswerKey) {
          standardAnswerKeySpan = "";
          color = "#2CB774";
        }
        span.innerHTML = `<span class="text_unline" style="padding: 0 20px;box-sizing: border-box;  border-bottom: 1px solid #000000;color: ${color}">${arr[i].userAnswerKey}${standardAnswerKeySpan}</span>`;
        item.value = arr[i].standardAnswerKey;
        item.style.display = "none";
        item.setAttribute("attr_name", "attr_value" + i);
        item.parentNode.insertBefore(span, item);
        // item.parent.removeChild(item)
        // this.filterHtml(html, arr)
      });
      return dom.innerHTML;
    },
    // getQuestions() {
    // //   this.$set(this.unfold, simIndex, !this.unfold[simIndex]);
    // //   if (this.sections[simIndex].simulationSections) {
    // //     return;
    // //   }
    //   pdfEvaluationReport({
    //     recordExamId: this.$route.query.studentExamId,
    //   }).then((res) => {
    //       console.log('res', res)
    //     res.data.result.sectionAnswerRecordsInfos.simulationExamQuestions =
    //       res.data.result.sectionAnswerRecordsInfos.sectionAnswerRecords;
    //     // this.$set(
    //     //   this.sections[simIndex],
    //     //   "simulationSections",
    //     //   res.data.result.sectionAnswerRecordsInfos
    //     // );
    //     // this.setSimulationExamQuestions(this.sections);
    //     // if (this.sections[simIndex].sectionType === 8) {
    //     //   this.addWriteAnsers(
    //     //     simIndex,
    //     //     res.data.result.sectionAnswerRecordsInfos
    //     //   );
    //     // }
    //   });
    // },
    ModuleTitleClass(id) {
      if (id == 3) {
        return "listening-mock-cloze";
      }
      if (id == 4) {
        return "completing-the-dialogue";
      }
      if (id == 5) {
        return "choose-wordsA";
      }
      if (id == 6) {
        return "reading-comprehension";
      }
      if (id == 7) {
        return "choose-wordsB";
      }
      if (id == 8) {
        return "written-expression";
      }
    },
    roman(id) {
      if (id == 0) {
        return "Ⅰ";
      }
      if (id == 1) {
        return "Ⅱ";
      }
      if (id == 2) {
        return "Ⅲ";
      }
      if (id == 3) {
        return "Ⅳ";
      }
      if (id == 4) {
        return "Ⅴ";
      }
      if (id == 5) {
        return "Ⅵ";
      }
      if (id == 6) {
        return "Ⅶ";
      }
      if (id == 7) {
        return "Ⅷ";
      }
    },
    getClassName(question, option) {
      if (question.standardAnswerKey === option.value) {
        if (question.userAnswerKey === option.value) {
          return ["enter-radio", "enter-text"];
        }
        return "enter-text";
      } else {
        if (question.userAnswerKey === option.value) {
          return ["error-radio", "error-text"];
        }
      }
      return "";
    },
    completeListening(simIndex, sectionId) {
      this.$emit("completeListening", simIndex, sectionId);
    },
    addWriteAnsers(index, questions) {
      this.$emit("addWriteAnsers", index, questions);
    },
    changeRadio(value, questionId, setionId) {
      this.$emit("changeRadio", value, questionId, setionId);
    },
    stopVoice() {
      this.$emit("stopVoice");
    },
    // getClassName(question, option) {
    //   this.$emit("getClassName", question, option);
    // },
  },
};
</script>

<style lang="scss" scoped>
.mock-check {
  position: relative;
  input {
    display: none;
  }
  ul {
    margin: 10px 0 0 0;
    padding: 0;
    top: 58px;
    display: none;
    list-style: none;
    position: absolute;
    border: 1px solid #e5e5e5;
    background: #ffffff;
    box-shadow: 0px 8px 7px 3px rgba(231, 230, 230, 0.57);
    z-index: 99;
    li {
      cursor: pointer;
      box-sizing: border-box;
      width: 172px;
      height: 36px;
      line-height: 36px;
      padding-left: 20px;
      text-align: left;
      background: #ffffff;
      color: #000000;
      font-size: 14px;
    }
    li:hover {
      background: #f0f0f0;
    }
  }
  label::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 10px solid #999999;
    transition: all 200ms ease-out;
    transform: translateX(11%) translateY(25%);
    transition: all 0.8s ease-in-out;
  }
  input[type="checkbox"]:checked + label::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: 10px solid #999999;
    transition: all 200ms ease-out;
    transform: translateX(11%) translateY(-25%);
    transition: all 0.8s ease-in;
  }
  input[type="checkbox"]:checked + label + ul {
    display: block;
  }
  .content {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
  }
}
.mock-check button.btn-secondary {
  height: 58px;
  background: #ffffff !important;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  color: #000000;
}
.parse-class {
  text-align: end;
  padding: 0;
}
.text-left {
  text-align: left;
}
.test-item {
  border-bottom: 2px solid #f0f0f0;
  padding: 1rem 0;
  .align-items-center {
    width: 100%;
    margin: 0 auto;
    line-height: 2;
  }
}
.section-title {
  font-weight: bold;
  font-size: 1rem;
}
.test-title {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 2;
}
.test-subtitle {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 2rem;
}
.answe-details {
  // font-size: 1.571rem;
  font-weight: bold;
  // line-height: 2;
}
.item-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #2eb976;
  margin: 2rem 0;
}
.testinfor-title {
  font-weight: bold;
  font-size: 1.1429rem;
  padding-bottom: 2rem;
  text-align: left;
  padding-top: 2rem;
}
.mock-test-back {
  min-height: 100vw;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0rem 2rem;
  letter-spacing: 1px;
  .mockTestContainer {
    padding: 0rem 24px !important;
  }
}
.text-left {
  text-align: left;
}
.down-button {
  cursor: pointer;
  color: #2cb774;
}
.test-prompt {
  font-size: 1.1429rem;
}
.resultFont,
.userInfo-content-error {
  font-size: 1.1429rem;
  font-weight: bold !important;
  padding-right: 4px;
}
.resultUnit {
  display: flex;
  align-items: center;
}
.green {
  color: #2cb774;
  padding-right: 0.5rem;
}
.yellow {
  color: #f58e08;
  padding: 0 0.5rem;
}
.module-title {
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.fullScoreEvaluation {
  display: flex;
  align-items: center;
}
.complete-listening {
  color: #fbaf4b;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 1rem;
  .iconerji {
    font-size: 20px;
    padding-right: 3px;
  }
}
.iconjiantou1,
.iconxiangxiajiantou {
  font-size: 14px;
}
.question-statement {
  text-indent: 10px;
}
//完形填空
.listening-mock-cloze {
  .stem-statement {
    padding: 0rem 23px 2rem 23px;
    text-align: left;
    line-height: 2.5;
  }
}
//阅读理解
.choose-wordsA {
  table {
    width: 100%;
  }
  .testinfor-title {
    text-align: center;
  }
  .question-statement {
    padding-bottom: 1rem;
  }
  .mockTestContainer {
    padding: 0.8rem 18px;
  }
  .stem-statement {
    padding: 0rem 23px 2rem 23px;
    text-align: left;
    line-height: 2.5;
  }
}

//补全对话
.completing-the-dialogue {
  .stem-statement {
    text-indent: 0px !important;
  }
  .mockTestContainer {
    padding: 1rem 18px;
  }
  .stem-statement {
    padding: 0rem 23px 2rem 23px;
    text-align: left;
    line-height: 2.5;
  }
}
// 选词填空
.reading-comprehension {
  table {
    text-align: center;
    margin: 0 auto;
  }
  .chooseWordsContent {
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
  .stem-statement {
    padding: 0rem 23px 2rem 23px;
    text-align: left;
    line-height: 2.5;
  }
}
//七选五
.choose-wordsB {
  text-align: left;
  .chooseWordsContent {
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
}
//书面表达
.written-expression {
  .written-expression-title {
    text-align: left;
    padding-left: 1.3rem;
  }
  .written-expression-topic {
    display: flex;
    .question-statement {
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .situational-application {
    text-align: left;
    line-height: 2;
    padding: 2.5rem 0rem;
  }
  table {
    margin: 0;
  }
  .own-answer {
    font-weight: bold;
    padding-left: 1rem;
    .own-answer-img {
      text-align: center;
      width: 20%;
      img {
        width: 100%;
      }
    }
  }
  .standard-answer {
    p {
      margin: 0;
    }
    padding-left: 1rem;
    & > div:nth-child(1) {
      font-weight: bold;
    }
    & > div:nth-child(2) {
      color: #2cb774;
    }
  }
  .Self-assessment-score {
    padding-left: 1rem;
    text-align: right;
    & > span:nth-child(2) {
      font-weight: bold;
      font-size: 1.1429rem;
    }
  }
  .iconpingjia-tianchong {
    font-size: 14px;
    color: #2cb774;
  }
  .mock-evaluation {
    color: #2c3e50;
    padding-left: 9px;
  }
}
.topic-right {
  padding-right: 8px;
}
.click-listening-text {
  text-decoration: underline;
  color: #f58e08;
  font-weight: 400 !important;
  cursor: pointer;
}
.unfold-mock-listening {
  width: 100%;
  padding-left: 0.4rem;
  padding-top: 1rem;
}
.QuestionContent {
  text-indent: 4.3rem;
}
.group-content {
  border-top: 5px solid #f0f0f0;
  padding: 0 0 2rem;
  margin: 0 0 2rem;
  line-height: 1.5;
  .group-contentTitle {
    display: flex;
    align-items: center;
    line-height: 2;
    text-indent: -26px;
    padding-left: 39px;
  }
  .group-stament {
    font-weight: bold;
  }
  .question-description {
    line-height: 2;
  }
  .BigTitlePlayButton {
    text-indent: -5.8px;
    .iconbofang1 {
      padding: 0 !important;
      margin-right: 6px;
    }
  }
  .fullScoreEvaluation {
    padding-left: 2.5rem;
  }
  .iconbofang1,
  .iconzanting {
    color: #fbb860;
    font-size: 1.5rem;
    cursor: pointer;
    text-indent: -8px;
    padding-right: 9px;
  }
  .iconzanting {
    color: #3fc886;
  }
  .row-content {
    padding: 1rem 0rem;
    padding-left: 5px;
  }
  .testQuestions {
    display: flex;
    align-items: center;
    .QuestionContent {
      text-indent: 1.3rem;
    }
  }
  .question-stament {
    padding: 0.5rem 0;
    font-weight: bold;
    .question-title {
      display: flex;
      align-items: center;
      text-indent: -3px;
    }
    .answerTitle {
      text-indent: 23px;
    }
  }
  .question-option {
    padding: 0.5rem 0;
    .custom-control {
      padding: 0;
      margin: 0;
    }
  }
  .icon-duihao {
    color: #2cb774;
    font-weight: bold;
  }
  .enter-radio
    ::v-deep
    .custom-control-input:checked
    ~ .custom-control-label::before {
    color: #fff;
    border-color: #2cb774;
    background-color: #2cb774 !important;
  }
  .error-radio
    ::v-deep
    .custom-control-input:checked
    ~ .custom-control-label::before {
    color: #fff;
    border-color: #cc0000 !important;
    background-color: #cc0000 !important;
  }
  .error-text {
    color: #cc0000;
  }
  .enter-text {
    color: #2cb774;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
}
.icon-dati {
  color: $text-green;
  // font-size: 20px;
}
.examQuestion-description {
  text-align: left;
}
</style>
<style lang="scss">
.written-expression-topic {
  display: flex;
  .question-statement {
    img {
      // width: 7rem;
      // height: 50px;
      max-width: 25rem;
    }
  }
}
.stem-statement {
  text-align: left;

  table {
    margin: 0 auto;
    * {
      margin: 0;
      padding: 0;
    }
    td {
      padding: 0 2em;
    }
  }
  // p {
  //   padding: 0px;
  //   margin: 0px;
  // }
}
.choose-wordsB {
  table {
    margin: 0;
    * {
      margin: 0;
      padding: 0;
    }
    td {
      padding-left: 2rem;
    }
  }
}
.mock-listeningText-modal {
  letter-spacing: 1px;
  .section-description {
    text-indent: -24px;
    padding: 1.05vw 0vw 1.05vw 1.05vw;
  }
  .listening-text {
    audio {
      height: 2rem;
    }
    .audio-player {
      margin-left: 1vw !important;
      outline: none !important;
    }
    .exam-title {
      text-align: center;
      font-size: 1.429rem;
      font-weight: bold;
      letter-spacing: 2px;
    }
  }
  .listening-div {
    display: flex;
    align-items: center;
    justify-content: end;
    line-height: 3;
  }
  .listening-div-D {
    padding: 1rem 0rem;
    .listening-text {
      width: 100%;
      line-height: 1;
      padding-left: 2.2rem;
      text-indent: -1.2rem;
      & > p:nth-child(2) {
        display: inline-block;
      }
      .audio-player {
        margin: 0;
      }
    }
  }
}

.mock-listeningText-dialog {
  max-width: 1000px !important;
}
.mock-listeningText-body {
  padding: 0 3rem !important;
  .listening-div-C {
    padding-left: 1rem;
    .listening-text {
      display: flex;
      span {
        padding-right: 6px;
      }
    }
  }
  .listening-div-D {
    .listening-text {
      p {
        span {
          br {
            display: block;
          }
        }
      }
    }
  }
  .listening-div-E {
    padding-left: 1rem;
    .listening-text {
      line-height: 1.5 !important;
    }
  }
  .listening-div-F {
    padding-left: 1rem;
    .listening-text {
      text-indent: 1.5rem;
      p {
        line-height: 2.5;
      }
    }
  }
}
// 选词填空
.reading-comprehension {
  table {
    text-align: center !important;
    float: none;
    margin: 0 auto;
    td {
      padding: 0;
    }
  }
  .chooseWordsContent {
    input {
      height: 22px !important;
      text-align: center !important;
      border-top: none !important;
      border-right: none !important;
      border-left: none !important;
      outline: none !important;
    }
  }
  // .stem-statement {
  //   & > p:nth-child(2){
  //     display: none;
  //   }
  // }
}
.standard-answer {
  p {
    margin: 0;
  }
}
.group-6 {
  #mocktest-coll4 {
    & > div:nth-child(2) {
      .stem-statement {
        p {
          img {
            width: 70%;
          }
        }
      }
    }
  }
}
//完形填空
.listening-mock-cloze {
  .question-option {
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }
}
</style>
