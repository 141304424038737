<template>
  <div class="week-new">
    <el-table
      style="width: 100%"
      :data="tableData"
      border
      :cell-style="tableRowStyle"
      :header-cell-style="tableHeaderColor"
      :span-method="objectSpanMethod"
    >
      <el-table-column label="报告位置" prop="name" min-width="20%">
        <template slot-scope="scope">
          <span class="text-link">{{ getModuleName(scope.row.sprintId) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="报告名称" min-width="35%" prop="reportName">
        <template slot-scope="scope">
          <span class="report-name" @click="viewReport(scope.row)">
            {{ scope.row.reportName }}
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="报告下载" min-width="20%">
        <template slot-scope="scope">
          <span
            class="report-name"
            @click="download(scope.row, scope.row.reportName)"
            >PDF</span
          >
        </template>
      </el-table-column> -->
      <el-table-column label="报告日期" min-width="25%">
        <template slot-scope="scope">
          <span>{{ scope.row.creationTime | dateFormat }}</span>
        </template>
      </el-table-column>
      <div slot="empty">你当前还没有体检报告，赶快去闯关吧~</div>
    </el-table>
    <div class="paf-download">
      <keep-alive>
        <component ref="report" :is="componentName" @complete="complete" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { getEngReportList } from "@/api/english-medical";
import ExaminationReport from "./components/examination/examination-report.vue";
import SmallReport from "../english-small/english-small-report.vue";
import BigReport from "./components/big/big-report.vue"
import mockReport from "./components/mock/mock-report.vue"
import { mapMutations } from "vuex";
export default {
  name: "ReportingContent",
  components: {
    ExaminationReport,
    SmallReport,
    BigReport,
    mockReport
  },
  data() {
    return {
      tableData: [],
      componentName: "",
    };
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(["setCurrentModuleId", "setMockTestName"]),
    download(sprint) { 
      console.log("下载", sprint);
      this.setCurrentModuleId(sprint.sprintId);
      this.setMockTestName(sprint.examName);
      if (sprint.sprintId == "1") {
        this.componentName = "ExaminationReport";
        this.$router.replace({
          path: this.$route.path,
          query: {
            studentExamId: sprint.recordExamId,
            sprintId: sprint.sprintId,
          },
        });
      }
      if (sprint.sprintId == "2") {
        this.componentName = "SmallReport";
        if (sprint.examCategoryId == 4) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              studentExamId: sprint.recordExamId,
              stageId: 1,
              sprintId: sprint.sprintId,
            },
          });
        }
        if (sprint.examCategoryId == 5) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              studentExamId: sprint.recordExamId,
              stageId: 3,
              sprintId: sprint.sprintId,
            },
          });
        }
      }
      if (sprint.sprintId == "4") {
        this.componentName = "BigReport";
        if (sprint.examCategoryId == 6) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              studentExamId: sprint.recordExamId,
              sprintId: sprint.sprintId,
              text: sprint.stageId,
              stageId: 1,
            },
          });
        }
        if (sprint.examCategoryId == 7) {
          this.$router.replace({
            path: this.$route.path,
            query: {
              studentExamId: sprint.recordExamId,
              sprintId: sprint.sprintId,
              text: sprint.stageId,
              stageId: 3,
            },
          });
        }
      }
      if (sprint.sprintId == "7") {
        this.componentName = "mockReport";
        this.$router.replace({
          path: this.$route.path,
          query: {
            studentExamId: sprint.recordExamId,
            state: 1,
            sprintId: sprint.sprintId,
          },
        });
      }
      this.downLoadPdf();
    },
    downLoadPdf() {
      // this.$nextTick(() => {
        this.$refs.report.downPdf();
      // });
    },
    complete(fn) {
      fn();
    },
    objectSpanMethod({ row, columnIndex }) {
      try {
        if (columnIndex === 0) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        }
      } catch (e) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },
    setrowspans() {
      // 先给所有的数据都加一个v.rowspan = 1
      this.tableData.forEach((item) => {
        item.rowspan = 1;
      });
      // 双层循环
      for (let i = 0; i < this.tableData.length; i++) {
        // 内层循环，上面已经给所有的行都加了v.rowspan = 1
        // 这里进行判断
        // 如果当前行的name和下一行的name相等
        // 就把当前v.rowspan + 1
        // 下一行的v.rowspan - 1
        for (let j = i + 1; j < this.tableData.length; j++) {
          //此处可根据相同字段进行合并，此处是根据的sprintId
          if (this.tableData[i].sprintId === this.tableData[j].sprintId) {
            this.tableData[i].rowspan++;
            this.tableData[j].rowspan--;
          }
        }
        // 这里跳过已经重复的数据
        i = i + this.tableData[i].rowspan - 1;
      }
    },
    tableRowStyle() {
      return "font-size:0.8333vw;text-align:center;padding:10px;";
    },
    tableHeaderColor() {
      return "background-color:#2BB773;color:#fff;=;font-weight:bold;text-align:center;";
    },
    getTableData(res) {
      //整理接口数据为  eltable 需要格式
      let tableList = [];
      for (let key in res) {
        for (let a of res[key]) {
          a["sprintId"] = key;
          tableList.push(a);
        }
      }
      this.tableData = tableList;
      this.setrowspans();
      console.log("报告列表", tableList);
    },
    init() {
      getEngReportList({}).then((res) => {
        this.getTableData(res.data.result);
        console.log("表格数据", this.tableData);
      });
    },
    viewReport(sprint) {
      this.setCurrentModuleId(sprint.sprintId);
      this.setMockTestName(sprint.examName);
      if (sprint.sprintId == 1) {
        this.$router.replace({
          name: "ExaminationReport",
          query: {
            // module: 4,
            studentExamId: sprint.recordExamId,
            sprintId: sprint.sprintId,
          },
        });
      }
      if (sprint.sprintId == 2) {
        //扫雷尖兵
        if (sprint.examCategoryId == 4) {
          this.$router.replace({
            name: "SmallModuleReport",
            query: {
              // module: 5,
              studentExamId: sprint.recordExamId,
              stageId: 1,
              sprintId: sprint.sprintId,
            },
          });
        }
        if (sprint.examCategoryId == 5) {
          this.$router.replace({
            name: "SmallModuleReport",
            query: {
              // module: 5,
              studentExamId: sprint.recordExamId,
              stageId: 3,
              sprintId: sprint.sprintId,
            },
          });
        }
      }
      if (sprint.sprintId == 4) {
        if (sprint.examCategoryId == 6) {
          this.$router.replace({
            name: "BigModuleReport",
            query: {
              // module: 6,
              studentExamId: sprint.recordExamId,
              sprintId: sprint.sprintId,
              text: sprint.stageId,
              stageId: 1,
            },
          });
        }
        if (sprint.examCategoryId == 7) {
          this.$router.replace({
            name: "BigModuleReport",
            query: {
              // module: 6,
              studentExamId: sprint.recordExamId,
              sprintId: sprint.sprintId,
              text: sprint.stageId,
              stageId: 3,
            },
          });
        }
      }
      if (sprint.sprintId == 7) {
        this.$router.replace({
          name: "MockReport",
          query: {
            // module: 7,
            studentExamId: sprint.recordExamId,
            state: 1,
            sprintId: sprint.sprintId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.report-name {
  text-decoration: underline;
  cursor: pointer;
}
.text-link {
  cursor: pointer;
}
.paf-download {
  width: 1660px;
  opacity: 0;
  height: 0;
}
.pdf-disabled {
  color: rgba(0, 0, 0, 0.3);
}
</style>