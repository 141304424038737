<template>
  <b-container>
    <div id="download-big-pdf">
      <div class="answer-examination-test-back">
        <div class="student-answer-information">
          <b-row>
            <EndHead :result="result" @downPdf="downPdf"> </EndHead>
          </b-row>
          <BigEndForm :result="result"> </BigEndForm>
        </div>
      </div>
      <div class="student-score-information">
        <EndForm
          :score="score"
          :subjectiveColor="`#2cb774`"
          :objectiveColor="`#FFC13B`"
          :objectiveTest="`客观题`"
          :subjectiveTest="`主观题`"
        >
        </EndForm>
      </div>
      <div class="student-test-information">
        <EndTest
          :sections="sections"
          :unfold="unfold"
          :examSectionInfos="examSectionInfos"
          @getQuestions="getQuestions"
          @addWriteAnsers="addWriteAnsers"
          @changeRadio="changeRadio"
          @filterHtml="filterHtml"
        >
        </EndTest>
      </div>
    </div>
  </b-container>
</template>

<script>
import {
  // getEngReport,
  getSectionAnswerRecords,
} from "@/api/english-examination";
import { engPdfTestReport } from "@/api/english-big";
import EndForm from "@/components/english-end/end-form.vue";
import EndTest from "../end-test.vue";
import EndHead from "@/components/english-end/end-head.vue";
import BigEndForm from "../../../english-big/components/big-end-form.vue";
export default {
  data() {
    return {
      result: "",
      score: {},
      sections: "",
      unfold: [true, true, true, true, true, true, true, true],
      examSectionInfos: [],
      htmlTitle:''
    };
  },
  components: {
    EndForm,
    EndTest,
    EndHead,
    BigEndForm,
  },
  mounted() {
    this.getSections();
    this.sections = this.simulationExamQuestions;
  },
  beforeRouteLeave(to, from, next) {
    sessionStorage.removeItem("simulationExamQuestions");
    next();
  },
  methods: {
    // returnHome() {
    //   this.$router.replace({
    //     name: "HomeIndex",
    //   });
    // },
    downPdf() {
      if (this.$route.query.stageId == 1) {
        this.htmlTitle = "培立优中考英语冲刺沙漠行动靶向诊断报告";
      }
      if (this.$route.query.stageId == 3) {
        this.htmlTitle = "培立优中考英语冲刺沙漠行动效果测评报告";
      }
      this.getPdf("download-big-pdf",this.htmlTitle);
    },
    getSections(id = this.$route.query.studentExamId) {
      this.check = false;
      this.unfold = [true, true, true, true, true, true, true, true];
      // debugger;

      if (id) {
        engPdfTestReport({
          recordExamId: this.$route.query.studentExamId,
        }).then((res) => {
          this.result = res.data.result.report.engExamBigTesReportDto;
          this.sections = res.data.result;
          this.examSectionInfos = res.data.result.report.engExamTesReports;
          this.score = res.data.result.report.scoreDistribution;
          // this.setSimulationExamQuestions(this.sections);
          // this.userAnswersInit();
          this.testData = res.data.result;
          console.log("this.testData", this.testData);
        });
      }
    },
    changeRadio(value, questionId, setionId) {
      console.log("模拟试卷", this.answers);
      for (const a of this.answers.answerSectionInputs) {
        if (a.sectionId === setionId) {
          let isHave = false;
          for (const b of a.answerQuestionInputs) {
            if (b.questionId === questionId) {
              b.answer = value;
              isHave = true;
              break;
            }
          }
          if (!isHave) {
            a.answerQuestionInputs.push({
              questionId: questionId,
              answer: value,
            });
          }
        }
      }
      this.setUserAnswers(this.answers);
    },
    // 非听力展开试题
    getQuestions(simIndex, sectionId) {
      console.log("sectionId", sectionId, "simIndex", simIndex);
      // debugger;
      this.$set(this.unfold, simIndex, !this.unfold[simIndex]);
      if (this.sections[simIndex].simulationSections) {
        return;
      }
      getSectionAnswerRecords({ sectionId: sectionId }).then((res) => {
        console.log("res", res);
        // debugger;
        res.data.result.sectionAnswerRecordsInfos.simulationExamQuestions =
          res.data.result.sectionAnswerRecordsInfos.sectionAnswerRecords;
        this.$set(
          this.sections[simIndex],
          "simulationSections",
          res.data.result.sectionAnswerRecordsInfos
        );
        this.setSimulationExamQuestions(this.sections);
        if (this.sections[simIndex].sectionType === 8) {
          this.addWriteAnsers(
            simIndex,
            res.data.result.sectionAnswerRecordsInfos
          );
        }
        console.log("模块试题", this.sections);
      });
    },
    addWriteAnsers(index, questions) {
      for (const a of questions) {
        for (const b of a.simulationExamQuestions) {
          this.answers.answerSectionInputs[index].answerQuestionInputs.push({
            selfScore: "",
            questionId: b.questionId,
            answer: "",
          });
        }
      }
      this.setUserAnswers(this.answers);
    },
    filterHtml(html, arr) {
      const dom = document.createElement("div");
      dom.innerHTML = html;
      const inputList = dom.getElementsByTagName("input");
      Array.prototype.forEach.call(inputList, (item, i) => {
        // debugger;

        const span = document.createElement("span");
        let color = "#CC0000";
        let standardAnswerKeySpan = `
          <span style="font-weight: bold;color: #000000;">（<span style="font-weight: normal;color: #2CB774">${arr[i].standardAnswerKey}</span>）</span>
        `;
        if (arr[i].userAnswerKey === arr[i].standardAnswerKey) {
          standardAnswerKeySpan = "";
          color = "#2CB774";
        }

        span.innerHTML = `<span class="text_unline" style="padding: 0 20px;box-sizing: border-box;  border-bottom: 1px solid #000000;color: ${color}">${arr[i].userAnswerKey}${standardAnswerKeySpan}</span>`;
        item.value = arr[i].standardAnswerKey;
        item.style.display = "none";
        item.setAttribute("attr_name", "attr_value" + i);
        item.parentNode.insertBefore(span, item);
        // item.parent.removeChild(item)
        console.log("input", item);
        // this.filterHtml(html, arr)
      });
      return dom.innerHTML;
    },
  },
};
</script>

<style lang="scss" scoped>
.answer-examination-test-back {
  letter-spacing: 1px;
  .student-answer-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
    position: relative;
  }
}
.student-score-information {
  background-color: #ffffff;
  min-height: 40rem;
  border-radius: 10px;
  padding: 3rem 2rem;
  margin: 1rem 0;
}
.student-test-information {
  background-color: #ffffff;
  min-height: 40rem;
  border-radius: 10px;
  padding: 3rem 2rem;
}
</style>>
