import { axios } from '@/utils/request'

const api = {
  GetEngReportList: '/services/app/EnglishReport/GetEngReportList'
}


export function getEngReportList(parameter) {
  return axios({
    url: api.GetEngReportList,
    method: "get",
    params: parameter,
  });
}