<template>
  <div class="wrapper">
      <div slot="answer-content" class="wrapper-content">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane name="first">
            <span slot="label" class="chuang-guan">闯关报告</span>
            <reporting-content />
          </el-tab-pane>
          <!-- <el-tab-pane name="second">
            <span slot="label" class="chuang-guan">学习周报</span>
            <weekly-content />
          </el-tab-pane> -->
        </el-tabs>
      </div>
  </div>
</template>

<script>
import ReportingContent from "@/views/english-medical/ReportingContent";

export default {
  name: "",
  components: {
    ReportingContent
  },
  data() {
    return {
      activeName: "first"
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  width: 100%;
  padding: 100px;
  .wrapper-content {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px 30px 0;
    border-radius: 5px;
  }
}
</style>