<template>
  <b-container>
    <div class="answer-mock-test-back" id="download-mock-pdf">
      <div class="student-answer-information">
        <EndHead :result="result" @downPdf="downPdf"> </EndHead>
        <!-- <EndCard :result="result"> </EndCard> -->
        <div class="end-card">
          <b-row :class="CardBg()">
            <b-col class="card-people d-none d-sm-block" sm="2">
              <img
                src="../../../../assets/images/index_pic4.png"
                class="card-img"
                alt=""
              />
            </b-col>
            <b-col sm="1" class="card-one">
              <b-row class="card-one-one">
                <span class="iconfont icon-defen2"></span>
                <span>{{ result.testScore }}</span>
              </b-row>
              <b-row><span>体检得分</span></b-row>
            </b-col>
            <b-col sm="9">
              <b-row align-v="center" class="ranking-order">
                <b-col sm="4" class="ranking-order-left">
                  <b-row>
                    <b-col class="medium-size">
                      超过<span class="card-top"
                        >{{ result.classRanking | turnPercentage }}%</span
                      >
                    </b-col>
                  </b-row>
                  <b-row><b-col class="medium-size">全班已测学生</b-col></b-row>
                  <b-row
                    ><b-col
                      ><span class="iconfont icon-tishi"></span
                      >当前班级完成测试人数<span class="iconTishi">
                        {{ result.classFinishNumber }}</span
                      >人</b-col
                    ></b-row
                  >
                </b-col>
                <b-col sm="4">
                  <b-row>
                    <b-col class="medium-size">
                      超过
                      <span class="card-top">
                        {{ result.schoolRanking | turnPercentage }}%</span
                      ></b-col
                    >
                  </b-row>
                  <b-row><b-col class="medium-size">全校已测学生</b-col></b-row>
                  <b-row
                    ><b-col
                      ><span class="iconfont icon-tishi"></span
                      >全校完成测试人数<span class="iconTishi">
                        {{ result.schoolFinishNumber }}</span
                      >人</b-col
                    ></b-row
                  >
                </b-col>
                <b-col sm="4">
                  <b-row>
                    <b-col class="medium-size">
                      超过<span class="card-top">
                        {{ result.districtRanking | turnPercentage }}%</span
                      ></b-col
                    >
                  </b-row>
                  <b-row><b-col class="medium-size">全区已测学生</b-col></b-row>
                  <b-row
                    ><b-col
                      ><span class="iconfont icon-tishi"></span
                      >全区完成测试人数<span class="iconTishi">
                        {{ result.districtFinishNumber }}</span
                      >人</b-col
                    ></b-row
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="card-summary">
            <div>
              <span class="iconfont icon-pingjia1"></span>
              <span class="card-test">体检总结</span>
            </div>
            <div class="summary-test" v-html="result.examSummary"></div>
          </b-row>
        </div>
      </div>
      <div class="student-score-information">
        <EndForm
          :score="score"
          :subjectiveColor="`#2CB774`"
          :listeningColor="`#FB8F67`"
          :objectiveColor="`#FFC13B`"
          :objectiveTest="objectiveTest"
          :subjectiveTest="subjectiveTest"
          :listeningTest="listeningTest"
          :answerQuestions="50"
        >
        </EndForm>
      </div>
      <div class="student-test-information">
        <EndTest
          :sections="sections"
          :unfold="unfold"
          :testData="testData"
          :voiceStates="voiceStates"
          :examSectionInfos="examSectionInfos"
          @addWriteAnsers="addWriteAnsers"
          @changeRadio="changeRadio"
          @filterHtml="filterHtml"
          @completeListening="completeListening"
          @stopVoice="stopVoice"
        >
        </EndTest>
      </div>
    </div>
  </b-container>
</template>

<script>
import { saveSimulation, pdfSimulationReport } from "@/api/english-mock";
import { mapMutations, mapState } from "vuex";
const imgURl = process.env.VUE_APP_API_BASE_URL + "/api/media/getanswerimage/";
import EndTest from "../end-test.vue";
// import EndCard from "../../components/english-end/end-card.vue";
import EndForm from "@/components/english-end/end-form.vue";
import EndHead from "@/components/english-end/end-head.vue";
var audio = new Audio();
import {
  getExamRecord,
  getOriginalListening,
  getSectionAnswerRecords,
} from "@/api/english-examination";
// import SelectQuestion from "@/components/english/SelectQuestion.vue";
export default {
  data() {
    return {
      check: false,
      sections: "",
      answers: {},
      result: "",
      imgURl,
      unfold: [true, true, true, true, true, true, true, true],
      textHtml: "",
      showLoading: false,
      testData: {},
      voiceStates: {},
      objectiveTest: "客观题",
      subjectiveTest: "主观题",
      listeningTest: "听力题",
      score: {
        // listeningFullScore: 0,
        // listeningAnswerScore: 0,
        // objectiveAnswerScore: 0,
        // objectiveFullScore: 0,
        // subjectiveAnswerScore: 0,
        // subjectiveFullScore: 0,
        // questionScores: [
        //   { questionNumber: 0, score: 0 },
        // ],
      },
      listeningRecordId: "",
      examSectionInfos: []
    };
  },
  components: {
    EndTest,
    // EndCard,
    EndForm,
    EndHead,
    // SelectQuestion,
    // "el-image": Image,
  },
  computed: {

    ...mapState(["simulationExamQuestions", "userAnswers", "mockTestName"]),
  },
  mounted() {
    this.getSections();
  },
  beforeRouteLeave(to, from, next) {
        sessionStorage.removeItem("userAnswers");
    sessionStorage.removeItem("simulationExamQuestions");
    next();
  },

  methods: {
    downPdf() {
      this.htmlTitle = `培立优${this.mockTestName}测评报告`
      this.getPdf("download-mock-pdf");
    },
    returnHome() {
      this.$router.replace({
        name: "HomeIndex",
      });
    },
    CardBg() {
      return "examination-bg";
    },
    startVoice(key, url) {
      console.log("音频url", url);
      audio.src = url;
      audio.play();
      this.voiceStates = {};
      this.$set(this.voiceStates, key, true);
      const self = this;
      audio.addEventListener("ended", function () {
        self.stopVoice();
      });

      console.log("播放状态管理", this.voiceStates);
    },
    stopVoice() {
      this.voiceStates = {};
      audio.pause();
    },
    openListeningModal() {
      // debugger;
      getOriginalListening({ examId: this.result.examId }).then((res) => {
  
        this.textHtml = res.data.result;
        this.$bvModal.show("modal-mock-listening");
        this.$nextTick(() => {
          var audios = document.getElementsByClassName("audio-player");
          console.log("音频暂停", audios);
          function pauseAll() {
            var self = this;
            [].forEach.call(audios, function (i) {
              i !== self && i.load();
            });
          }
          [].forEach.call(audios, function (i) {
            i.addEventListener("play", pauseAll.bind(i));
          });
        });
      });
    },
    roman(id) {
      if (id == 0) {
        return "Ⅰ";
      }
      if (id == 1) {
        return "Ⅱ";
      }
      if (id == 2) {
        return "Ⅲ";
      }
      if (id == 3) {
        return "Ⅳ";
      }
      if (id == 4) {
        return "Ⅴ";
      }
      if (id == 5) {
        return "Ⅵ";
      }
      if (id == 6) {
        return "Ⅶ";
      }
      if (id == 7) {
        return "Ⅷ";
      }
    },
    ModuleTitleClass(id) {
      if (id == 3) {
        return "listening-mock-cloze";
      }
      if (id == 4) {
        return "completing-the-dialogue";
      }
      if (id == 5) {
        return "choose-wordsA";
      }
      if (id == 6) {
        return "reading-comprehension";
      }
      if (id == 7) {
        return "choose-wordsB";
      }
      if (id == 8) {
        return "written-expression";
      }
    },
    filterTextareaHtml(html, arr) {
      // debugger;
      const dom = document.createElement("div");
      dom.innerHTML = html;
      const inputList = dom.getElementsByTagName("input");
      Array.prototype.forEach.call(inputList, (item, i) => {
        // debugger;

        const span = document.createElement("span");
        let color = "#CC0000";
        let standardAnswerKeySpan = `
          <span style="font-weight: bold;color: #000000;">（<span style="font-weight: normal;color: #2CB774">${arr[i].standardAnswerKey}</span>）</span>
        `;
        if (arr[i].userAnswerKey === arr[i].standardAnswerKey) {
          standardAnswerKeySpan = "";
          color = "#2CB774";
        }

        span.innerHTML = `<span class="text_unline" style="padding: 0 20px;box-sizing: border-box;  border-bottom: 1px solid #000000;color: ${color}">${arr[i].userAnswerKey}${standardAnswerKeySpan}</span>`;
        item.value = arr[i].standardAnswerKey;
        item.style.display = "none";
        item.setAttribute("attr_name", "attr_value" + i);
        item.parentNode.insertBefore(span, item);
        // item.parent.removeChild(item)
        console.log("input", item);
        // this.filterHtml(html, arr)
      });
      return dom.innerHTML;
    },
    filterHtml(html, arr) {
      const dom = document.createElement("div");
      dom.innerHTML = html;
      const inputList = dom.getElementsByTagName("input");
      Array.prototype.forEach.call(inputList, (item, i) => {
        // debugger;

        const span = document.createElement("span");
        let color = "#CC0000";
        let standardAnswerKeySpan = `
          <span style="font-weight: bold;color: #000000;">（<span style="font-weight: normal;color: #2CB774">${arr[i].standardAnswerKey}</span>）</span>
        `;
        if (arr[i].userAnswerKey === arr[i].standardAnswerKey) {
          standardAnswerKeySpan = "";
          color = "#2CB774";
        }

        span.innerHTML = `<span class="text_unline" style="padding: 0 20px;box-sizing: border-box;  border-bottom: 1px solid #000000;color: ${color}">${arr[i].userAnswerKey}${standardAnswerKeySpan}</span>`;
        item.value = arr[i].standardAnswerKey;
        item.style.display = "none";
        item.setAttribute("attr_name", "attr_value" + i);
        item.parentNode.insertBefore(span, item);
        // item.parent.removeChild(item)
        console.log("input", item);
        // this.filterHtml(html, arr)
      });
      return dom.innerHTML;
    },
    ...mapMutations(["setSimulationExamQuestions", "setUserAnswers"]),
    cancleTest() {
      this.$router.replace({
        name: "mock",
      });
    },
    getInput(index, questionId) {
      var h = document
        .getElementsByClassName(questionId)[0]
        .getElementsByTagName("input");
      console.log("html答案", index, h);
      const values = Array.prototype.map.call(h, (item) => item.value);
      var sesValue = JSON.parse(sessionStorage.getItem("values"))
        ? JSON.parse(sessionStorage.getItem("values"))
        : {};
      sesValue[questionId] = values;
      window.sessionStorage.setItem("values", JSON.stringify(sesValue));
    },
    textareaChange(value, index, questionId, state) {
      console.log("写作内容", value, index, questionId);
      for (const a of this.answers.answerSectionInputs[index]
        .answerQuestionInputs) {
        if (a.questionId === questionId) {
          if (state === 0) a.answer = value;
          if (state === 1) a.selfScore = value;
          break;
        }
      }
      this.setUserAnswers(this.answers);
    },
    submitTest() {
      const obj = JSON.parse(sessionStorage.getItem("values"));
      if (obj) {
        for (const key in obj) {
          var answer = "";

          for (let i = 0; i < obj[key].length; i++) {
            if (i === obj[key].length - 1) {
              answer += obj[key][i];
            } else {
              answer += obj[key][i] + "|";
            }
          }
          this.answers.answerSectionInputs[5].answerQuestionInputs.push({
            questionId: key,
            answers: answer,
          });
        }
      }
      const parmeter = this.answers;
      saveSimulation(parmeter).then(() => {});
    },
    changeRadio(value, questionId, setionId) {
      console.log("模拟试卷", this.answers);
      for (const a of this.answers.answerSectionInputs) {
        if (a.sectionId === setionId) {
          let isHave = false;
          for (const b of a.answerQuestionInputs) {
            if (b.questionId === questionId) {
              b.answer = value;
              isHave = true;
              break;
            }
          }
          if (!isHave) {
            a.answerQuestionInputs.push({
              questionId: questionId,
              answer: value,
            });
          }
        }
      }
      this.setUserAnswers(this.answers);
    },
    getSections(id = this.$route.query.studentExamId) {
      this.check = false;
      this.unfold = [true, true, true, true, true, true, true, true];

      if (id) {
        pdfSimulationReport({ recordExamId: id }).then((res) => {
          console.log("xinxi", res);
          this.result = res.data.result.engReport.engFinishSummary;
          this.listeningRecordId = res.data.result.engReport.listeningRecordId;
          this.sections = res.data.result;
          this.examSectionInfos = res.data.result.engReport.examSectionInfos;
          this.score = res.data.result.engReport.scoreDistribution;
          this.testData = res.data.result.listeningRecord;
          this.setSimulationExamQuestions(this.sections);
          this.userAnswersInit();
        });
      }
    },
    userAnswersInit() {
      this.answers = {
        examId: this.$route.query.studentExamId,
        answerMinutes: 0,
        answerSectionInputs: [],
      };
      for (const a of this.sections) {
        this.answers.answerSectionInputs.push({
          sectionId: a.sectionId,
          sectionType: a.sectionType,
          answerQuestionInputs: [],
        });
      }
      this.setUserAnswers(this.answers);
    },
    completeListening(simIndex, sectionId) {
      console.log("sectionId", sectionId);
      this.$set(this.unfold, simIndex, !this.unfold[simIndex]);
      if (this.simulationExamQuestions[simIndex].simulationSections) {
        return;
      }
      getExamRecord({ recordExamId: this.listeningRecordId }).then((res) => {
        this.showLoading = false;
        this.testData = res.data.result;
        console.log("url", this.testData);
        this.$set(
          this.sections[simIndex],
          "simulationSections",
          res.data.result.sectionAnswerExamQuestions
        );
        this.setSimulationExamQuestions(this.sections);
      });
    },
    getQuestions(simIndex, sectionId) {
      this.$set(this.unfold, simIndex, !this.unfold[simIndex]);
      if (this.simulationExamQuestions[simIndex].simulationSections) {
        return;
      }

      // let index = -1;
      // for (let i = 0; i < this.sections.length; i++) {
      //   if (
      //     this.sections[i].sectionId === sectionId &&
      //     !this.sections[i].simulationExamQuestions
      //   ) {
      //     index = i;
      //     break;
      //   }
      // }
      getSectionAnswerRecords({ sectionId: sectionId }).then((res) => {
        res.data.result.sectionAnswerRecordsInfos.simulationExamQuestions =
          res.data.result.sectionAnswerRecordsInfos.sectionAnswerRecords;
        this.$set(
          this.sections[simIndex],
          "simulationSections",
          res.data.result.sectionAnswerRecordsInfos
        );
        this.setSimulationExamQuestions(this.sections);
        if (this.sections[simIndex].sectionType === 8) {
          this.addWriteAnsers(
            simIndex,
            res.data.result.sectionAnswerRecordsInfos
          );
        }
        console.log("模块试题", this.sections);
      });
    },
    addWriteAnsers(index, questions) {
      for (const a of questions) {
        for (const b of a.simulationExamQuestions) {
          this.answers.answerSectionInputs[index].answerQuestionInputs.push({
            selfScore: "",
            questionId: b.questionId,
            answer: "",
          });
        }
      }
      this.setUserAnswers(this.answers);
    },
    getSimulation(id) {
      if (this.simulationExamQuestions[id]) {
        return this.simulationExamQuestions[id].simulationExamQuestions;
      } else {
        return [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.end-card {
  padding-top: 5rem;
  .card-one {
    padding-top: 8%;
    .card-one-one {
      font-size: 22px;
      :last-child {
        font-weight: bold;
      }
    }
    .icon-defen2 {
      font-size: 22px;
      color: #ffc13b;
      padding-right: 5px;
    }
  }
  .card-test {
    font-weight: bold;
  }
  .card-img {
    width: 100%;
  }
  .examination-bg {
    color: $text-white;
    background-image: url("../../../../assets/images/examination-bg.png");
    background-size: 100%;
    height: 16rem;
    background-repeat: no-repeat;
    margin: 0 auto;
    .card-people {
      position: relative;
      img {
        position: absolute;
        top: -17px;
        left: -15px;
      }
    }
  }
  .card-summary {
    margin: 0 auto;
    text-align: left;
    padding-top: 2rem;
    .icon-pingjia1 {
      color: $text-green;
      font-size: 1.2rem;
      padding-right: 5px;
    }
    .summary-test {
      padding-top: 1rem;
      color: #000;
      line-height: 1.8;
    }
  }
}
.card-top {
  font-size: 26px;
  font-weight: bold;
}
.ranking-order {
  height: 100%;
  text-align: center;
  .ranking-order-left {
    border-left: 1px solid #2ddbb3;
  }
  .icon-tishi {
    padding-right: 0.3rem;
  }
  .icon-tishi,
  .iconTishi {
    color: #ffc13b;
  }
  .medium-size {
    font-size: 1.1rem;
  }
}
.answer-mock-test-back {
  letter-spacing: 1px;
  .student-answer-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
  }
  .student-score-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
    margin: 1rem 0;
  }
  .student-test-information {
    background-color: #ffffff;
    min-height: 40rem;
    border-radius: 10px;
    padding: 3rem 2rem;
  }
  .examination-title {
    font-size: 1.571rem;
    color: $text-green;
    text-align: left;
    font-weight: bold;
  }
  .examination-right {
    line-height: 2;
    color: $text-light-gray;
    .iconfont {
      font-weight: bold;
      padding-right: 3px;
    }
  }
  .user-info {
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    color: $text-light-gray;
    border-bottom: 1px solid #e5e5e5;
    & > div {
      padding-right: 4.5rem;
      padding-left: 1rem;
      span {
        font-weight: bold;
      }
    }
  }
}
</style>